<template>
  <div class="to-product">
    <div class="title">
      <h1>產品詳細資料</h1>
      <span>PRODUCT DETAILS</span>
    </div>
    <el-card
      v-if="details"
      class="card"
    >
      <div class="product-model">
        <div class="model-img">
          <img
            v-if="details.ProdLineName === '除油煙機'"
            src="../assets/img/除油煙機.png">
          <img
            v-else
            :src="details.ProdImage"
            alt=""
          >
        </div>
        <div class="model">
          <h1 class="prod-name">
            {{ details.ProdLineName }}
          </h1>
          <h2 class="model-name">
            型號：{{ details.ModelTypeName }}
          </h2>
          <span>製造號碼：<span class="text"> {{ details.MFNO }} </span></span>
          <span>安裝日期：<span class="text"> {{ details.InstallDate }} </span></span>
          <span>安裝地址：<span class="text"> {{ details.Addr }} </span></span>
        </div>
        <!-- <div class="btn">
          居家環境水質分析
        </div> -->
      </div>
    </el-card>
    <!-- <el-row :gutter="15" v-if='!details.IsOldWaterProduct'> -->
    <el-row
      v-if="details.ProdLineName === '淨水器'"
      :gutter="15"
    >
      <el-col
        v-for="(item,index) in details.Detail"
        :key="index"
        :xs="12"
        :sm="12"
        :md="8"
      >
        <div @click="clickFilterState(item,details)">
          <filter-state
            :list="item"
            :is-height="'32rem'"
            @timeClick="timeClick(item,details)"
            @redClick="redClick(item,details)"
          />
        </div>
      </el-col>
    </el-row>
    <el-card
      v-else
      class="card2"
    >
      <div class="detail">
        <div class="type">
          <span>油網型態：<span class="text">{{ details.OilType }} </span></span>
          <span>油網寄送紀錄：<span class="text">{{ details.OilDeliverTime }} </span></span>
        </div>
        <hr>
        <div class="illustrate" v-if="!details.OilDeliverTime">
          <h3>
            若您府上的除油煙幾仍在使用中,卻無上方寄送紀錄,可點選下方按鈕申請油網寄送服務
          </h3>
        </div>
        <div
          v-if="!details.OilDeliverTime"
          class="btn"
          @click="Apply()"
        >
          <span>申請</span>
        </div>
      </div>
    </el-card>
    <guide-two
      :is-dialog-show="isShowGuideTwo"
      :no-show-click="noShowClick"
      :no-click="noClick"
      :filter="true"
    />
    <guide-two
      :is-dialog-show="isShowGuideTwos"
      :filter_="true"
    />
    <Loading :show="isShowLoadging" />
  </div>
</template>

<script>
import FilterState from '@/components/FilterState'
import GuideTwo from '@/components/PopUp/Guide_2'
import { getByProductId, actionLog } from '../api/api'
import storage from './../storage'
export default {
  components: {
    FilterState,
    GuideTwo
  },
  data () {
    return {
      isShowGuideTwo: false,
      isShowGuideTwos: false,
      isShowLoadging: true,
      CustProdId: '',
      LINEMid: '',
      details: {},
      userName: {}
    }
  },
  async mounted () {
    await this.liffInit(process.env.VUE_APP_LIFFID_UPLOAD_FILTERPUSH)
    this.CustProdId = this.$route.params && this.$route.query.custProdId
    storage.setItem('CustProdId', this.CustProdId)
    this.LINEMid = storage.getItem('LINEMid')
    console.log('LINEId =', this.LINEMid)
    this._getByProductId()
  },
  methods: {
    _getByProductId () {
      const { CustProdId } = this
      getByProductId({
        CustProdId
      }).then((res) => {
        this.details = res.data.Data[0]
        // todo: 假資料測試完後移除
        if (res.data.State) { this.details = res.data.Data[0] } else {
          this.details = {
            CustProdId: '74cca67a-a9da-ec11-81f6-005056af7974',
            ProdLineId: 'bfe7586b-b30d-e611-80c5-000c29188d63',
            ProdLineName: '除油煙機',
            ProdLineCode: 1,
            ProdCateId: 'e5015c51-7a17-e611-80c5-000c29188d63',
            ProdCateName: '除油煙機',
            ProdCateCode: null,
            ProdSeriesCode: 'A0',
            ModelTypeId: 'aeacde3f-b2db-e611-80c5-005056af7974',
            ModelTypeName: 'DR3592AXL',
            MFNO: '1A00000999916',
            Addr: '420-台中市中港路1號',
            InstallDate: '2022-05-21',
            IsOriginalService: true,
            OilType: '錐網',
            OilDeliverTime: '',
            ProdImage: 'https://buy.sakura.com.tw/media/6207/P0230.png',
            MaintainFlag: '定期保養',
            IsOldWaterProduct: false,
            Charge: 0,
            Detail: null
            // todo: 假資料待改
            // Detail: [
            //   {
            //     Sequence: '1',
            //     LastChangeDate: '2021-03-31',
            //     SuggestChangeDate: '2021-10-31',
            //     FilterMfno: '',
            //     FilterName: 'F0161',
            //     FilterDescription: '複合式濾芯(活性碳+PP)',
            //     FilterDetail:
            //       '過濾頭髮、泥沙、鐵銹、雜質等固體沈澱物、氯、異色、異味。',
            //     FilterSize: '',
            //     RemainLife: '10%',
            //     RemainDays: 0,
            //     IsNeedToChange: true,
            //     IsBinding: true
            //   },
            //   {
            //     Sequence: '2',
            //     LastChangeDate: '2021-03-31',
            //     SuggestChangeDate: '2022-04-30',
            //     FilterMfno: '',
            //     FilterName: 'F0152',
            //     FilterDescription: '後置活性碳',
            //     FilterDetail: '過濾氯、異色、異味，提升口感。',
            //     FilterSize: '',
            //     RemainLife: '80%',
            //     RemainDays: 0,
            //     IsNeedToChange: true,
            //     IsBinding: true
            //   },
            //   {
            //     Sequence: '3',
            //     LastChangeDate: '2021-03-31',
            //     SuggestChangeDate: '2023-04-30',
            //     FilterMfno: '',
            //     FilterName: 'F0181',
            //     FilterDescription: 'RO膜(400G)',
            //     FilterDetail:
            //         '過濾氯化物、農藥、致癌物質三氯甲烷、重金屬、細菌。',
            //     FilterSize: '',
            //     RemainLife: '32%',
            //     RemainDays: 244,
            //     IsNeedToChange: false,
            //     IsBinding: true
            //   }
            // ]
          }
        }
        // this.$store.dispatch('getMeProduct', res.data.Data[0])
        this.isShowLoadging = false
        // console.log('r', this.details)
        console.log(this.details.OilDeliverTime)
      })
    },
    clickFilterState (item, id) {
      this.$router.push({ path: '/toView', query: { Sequence: item.Sequence, custProdId: id.CustProdId } })
    },
    // 點擊匹配
    theHorseClick () {
      this.isShowGuideTwo = true
    },
    // 下一步
    noShowClick () {
      this.isShowGuideTwo = false
      this.$router.push({ name: 'YourDealer' })
    },
    // 略過
    noClick () {
      this.isShowGuideTwo = false
      this.$router.push({ name: 'YourDealer2' })
    },
    // 建議更換濾心
    timeClick (item, id) {
      // this.$router.push({ name: 'FilterToView' })
      // this.clickFilterState()
      this.$router.push({ path: '/toView', query: { Sequence: item.Sequence, custProdId: id.CustProdId, LINEMid: this.LINEMid, cid: this.userName.CustId } })
    },
    redClick (item, id) {
      this.$router.push({ path: '/toView', query: { Sequence: item.Sequence, custProdId: id.CustProdId, LINEMid: this.LINEMid, cid: this.userName.CustId } })
    },
    Apply () {
      actionLog({
        userId: this.LINEMid,
        action: 'click-apply-oil-net',
        cid: this.userName.CustId
      })
      window.location.href = `https://service.sakura.com.tw/Service.aspx?utm_term=${this.LINEMid}&pid=${this.CustProdId}&sid=3d3d5778-7d88-e711-80df-005056af7974`
    }
  }
}
</script>

<style scoped lang="scss">
.to-product {
  .title {
    margin-bottom: 3rem;
    text-align: center;
    color: #131313;
    h1 {
      line-height: 4.4rem;
      font-size: 3.1rem;
      font-weight: lighter;
    }
    span {
      line-height: 1.7rem;
      font-size: 1.2rem;
      font-weight: lighter;
    }
  }
  .card {
    border-radius: 1rem;
    padding: 2rem 3rem;
    margin-bottom: 2rem;
    .product-model {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .model-img {
        flex: 0 0 10.3rem;
        width: 10.3rem;
        margin-right: 1.5rem;
        img {
          width: 10.3rem;
        }
      }
      .model {
        display: flex;
        flex-direction: column;
        margin-right: 1.5rem;
        margin-bottom: 1rem;
        .prod-name {
          color: #131313;
          font-weight: 700;
          font-size: 18px;
          margin-bottom: .6rem;
        }
        .model-name {
          line-height: 2rem;
          margin-bottom: .6rem;
          font-size: 18px;
          font-weight: 700;
          color: #c8161d;
        }
        span {
          line-height: 2.1rem;
          font-size: 14px;
          color: #868686;
        }
        .text {
          color: #131313;
        }
      }
      .btn {
        width: 16.8rem;
        height: 4.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        background: #c8161d;
        border-radius: .6rem;
        color: #fff;
        }
  }
  }
  .card2{
    border-radius: 1rem;
    .detail{
      // display: flex;
      justify-content: space-between;
        .type {
          display: flex;
          flex-direction: column;
          margin-bottom: 1.5rem;
          margin-top: 0.5rem;
          margin-left: 5rem;
          span {
            line-height: 2.8rem;
            font-size: 14px;
            color: #868686;
          }
          .text {
            color: #131313;
          }
        }
        .btn {
          margin-top: 2rem;
          display: flex;
          justify-content: center;
          span {
            width: 8rem;
            height: 2.8rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: .4rem;
            background: #c8161d;
            font-size: 1.2rem;
            color: #fff !important;
          }
      }
        .illustrate{
          text-align: center;
          margin-top: 2rem;
          color: #c8161d;
          font-weight: 200;
          font-size: 14px;
        }

        hr{
          color:#868686;
          size: 1;
          align-items: center;
          width: 85%;
        }
  }
    }
  @media screen and (max-width:1199px) {
    .card  {
      padding: 1rem;
    }
  }
  @media screen and (max-width:991px) {
    .card {
      .product-model {
        flex-wrap: wrap!important;
        .btn {
          margin-top: 2rem;
          margin-left: 2rem;
        }
      }
    }
  }
  @media screen and (max-width:767px) {
    .card {
      .product-model {
        justify-content: flex-start;
        .model-img {
          margin-right: 1.8rem;
        }
      }
    }
  }
  @media screen and (max-width:500px) {
    .card {
      /deep/ .el-card__body {
        padding: 1rem;
      }
      .product-model {

        .model-img {
            flex: 0 0 7.4rem;
            width: 7.4rem;
            margin-right: 1.5rem;
            img {
              width: 7.4rem;
            }
        }
        .model {
          margin-right: 0;
        }
        .btn {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}
</style>
